function timeout(delay: number) {
  return new Promise((_resolve, reject) => {
    setTimeout(() => {
      reject(new Error('Request timed out'));
    }, delay);
  });
}

function createFetchRequest(
  method: string,
  url: string,
  authToken: string,
  body: {[key: string]: string} | undefined
) {
  const apiTimeout = 10000;
  const timeoutPromise = timeout(apiTimeout);
  const options = {
    method,
    headers: {
      Authorization: `Bearer ${authToken}`
    },
    body: JSON.stringify(body)
  };

  const fetchPromise = fetch(url, options).then((response) => {
    const {status} = response;
    if (status >= 200 && status < 400) {
      return response.text().then((text) => {
        return text && text.toLowerCase() !== 'ok' ? JSON.parse(text) : {};
      });
    }
    return Promise.reject(response);
  });

  return Promise.race([fetchPromise, timeoutPromise]);
}

function get(
  url: string,
  authToken: string,
  body: {[key: string]: string} | undefined
) {
  return createFetchRequest('GET', url, authToken, body);
}

export {get};
