import {h} from 'preact';
import type {QbrRelatedAccount, QbrRelatedAccounts} from 'src/@types';
import styles from './LoginQbrConnectAccount.module.scss';

function getAccountCards(connectedAccounts: QbrRelatedAccount[]) {
    return (
        <div className={styles[`qbr-connect-section__accounts-container`]}>
            {connectedAccounts.map((relatedAccount: QbrRelatedAccount) => {
                const accountName =
                    relatedAccount.account.qbrMember.businessName.replaceAll(' ', '_');
                const nomineeId = relatedAccount.account.nominee.nomineeId;
                return (
                    <div
                        data-testid={`qbr-connect-account-${accountName}`}
                        className={styles[`qbr-connect-section__connected-accounts`]}
                        key={accountName}
                    >
                        <a
                            href={`https://www.qantasbusinessrewards.com/login?nominee-id=${nomineeId}`}
                            target="_blank"
                            rel="noreferrer"
                        >
              <span className={styles.linkText}>
                {relatedAccount.account.qbrMember.businessName}
              </span>
                            <svg
                                width="24"
                                height="25"
                                viewBox="0 0 24 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M9 16.7075L13.3266 12.5L9 8.2925L10.332 7L16 12.5L10.332 18L9 16.7075Z"
                                    fill="#323232"
                                />
                            </svg>
                        </a>
                    </div>
                );
            })}
        </div>
    );
}

export function LoginQbrConnectAccount({
                                           qbrRelatedAccounts
                                       }: Readonly<{
    qbrRelatedAccounts: QbrRelatedAccounts | undefined;
}>) {
    if (!qbrRelatedAccounts) {
        return null;
    }
    const relatedAccountsArray = qbrRelatedAccounts?.relatedAccounts;
    const connectedAccounts = relatedAccountsArray.filter(
        (relatedAccount: QbrRelatedAccount) =>
            relatedAccount.relationType === 'connection'
    );
    if (connectedAccounts.length > 0) {
        return (
            <div className={styles[`qbr-connect-section`]}>
                <p className={styles[`qbr-connect-section__heading`]}>
                    Qantas Business Rewards
                </p>
                {getAccountCards(connectedAccounts)}
            </div>
        );
    }
    return null;
}
