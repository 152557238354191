// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qdd-login-menu__kHNZ9{width:100vw;background:#fff;border-top:4px solid #da0000;border-radius:0 0 4px 4px;box-shadow:rgba(0,0,0,.1) 0 0 15px 0;z-index:1}.qdd-login-menu__header__39fqB{font-size:1.125rem}@media(min-width: 1024px){.qdd-login-menu__kHNZ9{position:absolute;top:100%;width:270px;border-top:0;z-index:100}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"qdd-login-menu": "qdd-login-menu__kHNZ9",
	"qdd-login-menu__header": "qdd-login-menu__header__39fqB"
};
export default ___CSS_LOADER_EXPORT___;
