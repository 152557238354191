import {h} from 'preact';
import {capitaliseName} from '../../utils';
import styles from './LoginProfile.module.scss';

export function LoginProfile() {
  const qffAuth = window['qff_auth'];
  const firstName = qffAuth?.getFirstName();
  const lastName = qffAuth?.getLastName();
  const userTier = qffAuth?.getMembershipTier();
  const EFFECTIVE_TIER = userTier?.trim()?.toUpperCase()?.replaceAll(' ', '_');
  const memberId = qffAuth?.getMemberId();
  const pointsBalance = qffAuth?.getPoints();
  const formattedPoints = Number(pointsBalance)?.toLocaleString('en-US');

  return (
    <div className={styles.profile} data-tier={EFFECTIVE_TIER}>
      <div className={styles[`profile__tier-gradient`]} />
      <p className={styles[`profile__username`]}>
        {capitaliseName(firstName)} {capitaliseName(lastName)}
      </p>
      <div className={styles[`profile__stats`]}>
        <p
          data-testid="tier-description"
          className={styles[`profile__stats--tier`]}
        >
          {userTier} Frequent Flyer
        </p>
        <p className={styles[`profile__stats--memberid`]}>Member: {memberId}</p>
        <p
          className={styles[`profile__stats--points`]}
          data-testid="points-count"
        >
          {formattedPoints} points
        </p>
      </div>
    </div>
  );
}
