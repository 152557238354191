import {h} from 'preact';
import {useEffect, useState} from 'preact/hooks';
import {LoginRibbonContainer} from '../LoginRibbonContainer';
import {LoginMenu} from '../LoginMenu';
import {handleEvent, isUserAuthenticated} from '../../utils';
import {getQbrRelatedAccounts} from '../../utils/services/getQbrRelatedAccounts';
import type {QbrRelatedAccounts} from 'src/@types';
import styles from './Login.module.scss';

export function Login() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [qbrRelatedAccounts, setQbrRelatedAccounts] =
    useState<QbrRelatedAccounts>();
  const [menuOpened, setMenuOpened] = useState(false);
  const qffAuth = window['qff_auth'];

  const handleMenuClick = () => setMenuOpened(!menuOpened);

  function handleCallSso() {
    const oauth = window['oauth'];
    oauth.sso();
  }

  function handleShoppingCartLoginRequest() {
    setMenuOpened(true);
  }

  function updateSoftIdentity() {
    if(isUserAuthenticated()){
      const displayName = `${
          qffAuth.getPreferredName() ?? qffAuth.getFirstName()
      }`;
      localStorage.setItem(
          'qff_soft_identity',
          JSON.stringify({
            displayName,
            lastUpdatedDate: new Date().toISOString(),
            versionKey: '1.0'
          })
      );
    }
  }

  function handleInit() {
    updateSoftIdentity();
    if (isUserAuthenticated() && !isAuthenticated) {
      setIsAuthenticated(true);
    }
  }

  useEffect(() => {
    handleInit();
    const loginMenu = document.querySelector(`#qdd-login-ribbon`);
    loginMenu?.addEventListener('keydown', (e) =>
      handleEvent(e as KeyboardEvent, setMenuOpened)
    );
    return () =>
      loginMenu?.removeEventListener('keydown', handleEvent as EventListener);
  }, []);

  window?.addEventListener('oAuthInitCompleted', handleCallSso);
  window?.addEventListener('loginState', handleShoppingCartLoginRequest);
  useEffect(() => {
    return () => {
      window?.removeEventListener('oAuthInitCompleted', handleCallSso);
      window?.removeEventListener('loginState', handleShoppingCartLoginRequest);
    };
  }, []);

  useEffect(() => {
    qffAuth.subscribeInitCompleted(() => updateSoftIdentity());
    qffAuth.subscribeLoginSuccess(() => {
      setIsAuthenticated(isUserAuthenticated());
    });
    qffAuth.subscribeLogoutSuccess(() => {
      setIsAuthenticated(false);
      localStorage.removeItem('qff_soft_identity');
    });
  }, []);

  useEffect(() => {
    async function fetchQbrRelatedAccounts() {
      const memberId = qffAuth.getMemberId();
      const accessToken = qffAuth.getAccessToken();
      const relatedAccounts = await getQbrRelatedAccounts(
        memberId,
        accessToken
      );
      setQbrRelatedAccounts(relatedAccounts);
    }

    if (isAuthenticated) {
      fetchQbrRelatedAccounts();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    window?.addEventListener('keydown', handleKeydown);
    return () => window?.removeEventListener('keydown', handleKeydown);
  }, []);

  function handleKeydown(e) {
    if (e.key === 'Escape') {
      setMenuOpened(false);
    }
  }

  return (
    <div
      data-testid="qdd-login-content"
      id={'qdd-login-ribbon'}
      className={styles['qdd-login-ribbon']}
      role="tablist"
    >
      <LoginRibbonContainer
        data-testid="qdd-login-ribbon"
        aria-expanded={menuOpened}
        handleMenuClick={handleMenuClick}
        menuOpened={menuOpened}
        isAuthenticated={isAuthenticated}
      />
      {menuOpened && (
        <LoginMenu
          data-testid="qdd-login-menu"
          menuOpened={menuOpened}
          isAuthenticated={isAuthenticated}
          qbrRelatedAccounts={qbrRelatedAccounts}
        />
      )}
    </div>
  );
}
