import { h } from "preact";
import { createPortal } from "preact/compat";
import { useEffect, useState } from "preact/hooks";
import type { LoginRibbonProps } from "src/@types";
import { LoginRibbon } from "../LoginRibbon";

export function LoginRibbonContainer(props: LoginRibbonProps) {
  const [loginRibbonElement, setLoginRibbonElement] =
    useState<HTMLElement | null>(null);

  useEffect(() => {
    const elements = document.querySelectorAll("#qdd-login-ribbon");
    elements.forEach((el) => {
      const isInsideHiddenParent = el.closest("[hidden]") !== null;
      if (el instanceof HTMLElement && !isInsideHiddenParent) {
        setLoginRibbonElement(el);
        el.classList.add("visible");
        el.setAttribute("data-testid", "qdd-login-ribbon-content");
      }
    });
  }, []);

  return loginRibbonElement
    ? createPortal(
        <LoginRibbon
          handleMenuClick={props.handleMenuClick}
          menuOpened={props.menuOpened}
          isAuthenticated={props.isAuthenticated}
        />,
        loginRibbonElement
      )
    : null;
}
