import {h} from 'preact';
import {LogoutButton} from '../LogoutButton';
import {LoginProfile} from '../LoginProfile';
import {LoginProfileLinks} from '../LoginProfileLinks';
import styles from './LoginMenu.module.scss';
import type {QbrRelatedAccounts, LoginProps} from 'src/@types';
import {LoginQBR} from '../LoginQBR/LoginQBR';

export function LoginMenu({
  menuOpened,
  isAuthenticated,
  qbrRelatedAccounts
}: LoginProps) {
  return isAuthenticated ? (
    <AuthenticatedView
      menuOpened={menuOpened}
      qbrRelatedAccounts={qbrRelatedAccounts}
    />
  ) : (
    <UnauthenticatedView />
  );
}

function AuthenticatedView({
  menuOpened,
  qbrRelatedAccounts
}: Readonly<{
  menuOpened: boolean | undefined;
  qbrRelatedAccounts: QbrRelatedAccounts | undefined;
}>) {
  const loginMenu = 'qdd-login-menu';
  const loginMenuAuthenticated = 'qdd-login-menu__authenticated';
  const loginMenuStyle = `${styles[loginMenu]} ${
    menuOpened && styles[loginMenuAuthenticated]
  }`;
  return (
    <div className={loginMenuStyle} data-testid="qdd-login-menu">
      <div>
        <LoginProfile />
        <LoginProfileLinks authenticated={true} />
        <LoginQBR qbrRelatedAccounts={qbrRelatedAccounts} />
        <LogoutButton />
      </div>
    </div>
  );
}

function UnauthenticatedView() {
  return (
    <div className={`${styles['qdd-login-menu']}`} data-testid="qdd-login-menu">
      <div>
        <LoginProfileLinks authenticated={false} />
      </div>
    </div>
  );
}
