import {h} from 'preact';
import {LoginBusinessRewards} from '../LoginBusinessRewards';
import {LoginQbrConnectAccount} from '../LoginQbrConnectAccount';
import type {QbrRelatedAccounts} from 'src/@types';

export function LoginQBR({
                             qbrRelatedAccounts
                         }: {
    qbrRelatedAccounts: QbrRelatedAccounts | undefined;
}) {
    const relatedAccountsArray = qbrRelatedAccounts?.relatedAccounts ?? [];
    const userHasLinkedAccounts = relatedAccountsArray?.some(
        (account) => account.relationType === 'connection'
    );

    return userHasLinkedAccounts ? (
        <LoginQbrConnectAccount qbrRelatedAccounts={qbrRelatedAccounts} />
    ) : (
        <LoginBusinessRewards />
    );
}
