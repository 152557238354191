// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profile__logout__cxKrD{font-family:\"Ciutadella\",Helvetica,Arial,sans-serif;color:#323232;font-style:normal;line-height:1.5;font-weight:400;font-size:16px;border:none;color:#e40000;background-color:#fff;padding:16px;transition:all .2s;width:100%;cursor:pointer;text-transform:uppercase;font-weight:600;border-bottom-left-radius:4px;border-bottom-right-radius:4px;letter-spacing:1.2px}.profile__logout__cxKrD>a{outline:3px solid rgba(0,0,0,0);outline-offset:-3px;text-decoration:underline;color:#e40000}.profile__logout__cxKrD>a:focus{outline-color:#8de2e0}.profile__logout__cxKrD>a:focus:not(:focus-visible){outline-color:rgba(0,0,0,0)}.profile__logout__cxKrD>a:focus-visible{outline-color:#8de2e0}.profile__logout__cxKrD>a:hover{color:#b60000}.profile__logout__cxKrD>a:active{color:#890000}.profile__logout__cxKrD:hover{background:#b60000;color:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profile__logout": "profile__logout__cxKrD"
};
export default ___CSS_LOADER_EXPORT___;
