import {h} from "preact";
import {Login} from "./components/Login/Login";

export interface Props {
    config: string;
}

const Container = () => {
    return (
        <div data-testid="widget-root">
            <Login />
        </div>
    );
};

export default Container;
