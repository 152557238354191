import deepmerge from 'deepmerge';
import {COOKIE_QFF_NUMBER_HASH} from '../constants';

interface AnalyticsDataType {
  event: {
    eventCategory: string;
    eventAction: string;
    eventLabel: string;
    eventType?: 'interaction';
  };
  journey: {
    journeyName?: string;
    journeyStep: string;
    pageUrl?: string;
    pageName?: string;
    referringUrl?: string;
  };
  product?: {
    productStartDate: string;
    productEndDate: string;
    productStartTime: string;
    productEndTime: string;
    productDurationDays: string;
  };
  user?: {
    qffId?: string;
    qffTier?: string;
  };
}

const getCookieValue = (cookieKey: string) => {
  const cookie = document.cookie
    ?.split('; ')
    .find((splitCookie) => splitCookie.startsWith(cookieKey));
  return cookie ? cookie.split('=')[1] : '';
};

const getBaseAnalyticsData: () => AnalyticsDataType = () => {
  const baseData: AnalyticsDataType = {
    event: {
      eventAction: 'Base action',
      eventCategory: 'Base category',
      eventLabel: 'Base Button',
      eventType: 'interaction'
    },
    journey: {
      journeyName: document.title,
      journeyStep: 'Journey Step',
      pageName: document.title,
      pageUrl: window.location.href,
      referringUrl: document.referrer
    },
    user: {
      qffId: getCookieValue(COOKIE_QFF_NUMBER_HASH),
      qffTier: window['qff_auth'].getMembershipTier()
    }
  };
  return baseData;
};

export const pushAnalyticsDataEvent = (
  customAnalyticsData: AnalyticsDataType
) => {
  const eventDetail = {
    detail: {
      analyticsData: deepmerge(getBaseAnalyticsData(), customAnalyticsData)
    }
  };
  const analyticsDataEvent = new CustomEvent('analyticsData', eventDetail);
  document.body.dispatchEvent(analyticsDataEvent);
};
