export interface MemberAccount {
  pointBalance: number;
  statusCredits: {
    total: number;
  };
}

export interface MemberProfile {
  memberId: string;
  title: string;
  firstName: string;
  lastName: string;
  salutation: string;
  preferredName: string;
  countryOfResidence: string;
}

export interface QantasFrequentFlyerTier {
  startDate: string;
  tierChangeReason: string;
  tierOverridden: boolean;
  tierCode: string;
  tierDescription: string;
  effectiveTier: string;
  effectiveTierDescription: string;
  tierType: string;
  tierTypeDescription: string;
  oneWorldTier: string;
}

export interface PointsClubTier {
  startDate: string;
  expiryDate: string;
  tierChangeReason: string;
  tierOverridden: boolean;
  level: string;
  levelDescription: string;
}

export interface MemberTier {
  qantasFrequentFlyerTier: QantasFrequentFlyerTier;
  pointsClubTier: PointsClubTier;
}

export interface MemberContent {
  memberAccount: MemberAccount;
  memberProfile: MemberProfile;
  memberTier: MemberTier;
  qbrRelatedAccounts: QbrRelatedAccounts;
}

export interface QbrMember {
  businessName: string;
  points: number;
  level: number;
  showTierLevel: boolean;
}

export interface Nominee {
  nomineeId: number;
  firstName: string;
  lastName: string;
  emailAddress: string;
}

export interface LoginProps {
  isAuthenticated?: boolean;
  menuOpened?: boolean;
  qbrRelatedAccounts?: QbrRelatedAccounts;
}

export interface LoginRibbonProps {
  handleMenuClick?: () => void;
  menuOpened: boolean;
  isAuthenticated: boolean;
}

export interface QbrRelatedAccount {
  relationType: string;
  connectionId: string;
  account: {
    qbrMember: {
      businessName: string;
      points?: number;
      level: number;
      showTierLevel: boolean;
    };
    nominee: {
      nomineeId: number;
      firstName: string;
      lastName: string;
      emailAddress: string;
    };
  };
}

export interface QbrRelatedAccounts {
  relatedAccounts: QbrRelatedAccount[];
}

export enum AuthState {
  LOGGED_OUT = 'LOGGED_OUT',
  LOGGED_IN = 'LOGGED_IN',
  EXISTING = 'EXISTING'
}
