import {h} from 'preact';
import {useState, useEffect} from 'preact/hooks';
import styles from './LoginBusinessRewards.module.scss';

export function LoginBusinessRewards() {
    const [loginContent, setLoginContent] = useState('Business Rewards Login');
    const [loginLink, setLoginLink] = useState('');

    const loginDialog = window[`loginDialog`];

    useEffect(() => {
        setLoginContent(loginDialog.ui.businessRewardsLoginLabel);
        setLoginLink(loginDialog.model.businessRewardsLoginUri);
    }, [loginDialog]);

    return (
        <a
            data-testid="business-rewards-login"
            className={styles[`qbr-section`]}
            href={loginLink}
        >
            <div className={styles[`qbr-section__heading`]}>
                <span className={styles.text}>{loginContent}</span>
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.40833 12.9917L9.58333 14.1667L13.75 10L9.58333 5.83333L8.40833 7.00833L10.5583 9.16667H2.5V10.8333H10.5583L8.40833 12.9917ZM15.8333 2.5H4.16667C3.24167 2.5 2.5 3.25 2.5 4.16667V7.5H4.16667V4.16667H15.8333V15.8333H4.16667V12.5H2.5V15.8333C2.5 16.75 3.24167 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V4.16667C17.5 3.25 16.75 2.5 15.8333 2.5Z"
                        fill="#323232"
                    />
                    <mask
                        id="mask0_919_17944"
                        style="mask-type:luminance"
                        maskUnits="userSpaceOnUse"
                        x="2"
                        y="2"
                        width="16"
                        height="16"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8.40833 12.9917L9.58333 14.1667L13.75 10L9.58333 5.83333L8.40833 7.00833L10.5583 9.16667H2.5V10.8333H10.5583L8.40833 12.9917ZM15.8333 2.5H4.16667C3.24167 2.5 2.5 3.25 2.5 4.16667V7.5H4.16667V4.16667H15.8333V15.8333H4.16667V12.5H2.5V15.8333C2.5 16.75 3.24167 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V4.16667C17.5 3.25 16.75 2.5 15.8333 2.5Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask0_919_17944)" />
                </svg>
            </div>
        </a>
    );
}
