// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qdd-login-ribbon__c97QR{position:relative;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:end;align-items:flex-end}@media(min-width: 1024px){.qdd-login-ribbon__c97QR{float:right}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"qdd-login-ribbon": "qdd-login-ribbon__c97QR"
};
export default ___CSS_LOADER_EXPORT___;
