import {get} from './requests';
import type {QbrRelatedAccounts} from 'src/@types';

export async function getQbrRelatedAccounts(
  memberId: string,
  authToken: string
): Promise<QbrRelatedAccounts> {
  return get(
    `${process.env.BASE_API_URL}/qbr/related-accounts?qffMemberId=${memberId}`,
    authToken,
    undefined
  );
}
