// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../assets/qbr-gradient.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qbr-connect-section__CWuHy{background-color:#f6f6f6;border-top:1px solid #e6e6e6;border-bottom:1px solid #e6e6e6;padding:16px 16px 0 16px;position:relative}.qbr-connect-section__CWuHy::before{content:\"\";position:absolute;top:0;left:0;right:0;bottom:0;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:left -90px top -4px;background-size:220px;background-attachment:local}.qbr-connect-section__heading__A5dyv{text-align:center;border-bottom:1px solid #323232;padding-bottom:12px;margin-bottom:16px;font-size:1.125rem;font-weight:600}.qbr-connect-section__accounts-container__J0ckN{margin:0}.qbr-connect-section__connected-accounts__-7-ps{padding:0 0 16px 0;position:relative}.qbr-connect-section__connected-accounts__-7-ps a{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-align:center;align-items:center;text-decoration:none;font-family:\"Ciutadella\",Helvetica,Arial,sans-serif;color:#323232;font-style:normal;line-height:1.5;font-weight:400;font-size:16px}.qbr-connect-section__connected-accounts__-7-ps a>a{outline:3px solid rgba(0,0,0,0);outline-offset:-3px;text-decoration:underline;color:#e40000}.qbr-connect-section__connected-accounts__-7-ps a>a:focus{outline-color:#8de2e0}.qbr-connect-section__connected-accounts__-7-ps a>a:focus:not(:focus-visible){outline-color:rgba(0,0,0,0)}.qbr-connect-section__connected-accounts__-7-ps a>a:focus-visible{outline-color:#8de2e0}.qbr-connect-section__connected-accounts__-7-ps a>a:hover{color:#b60000}.qbr-connect-section__connected-accounts__-7-ps a>a:active{color:#890000}.qbr-connect-section__connected-accounts__-7-ps:hover a{color:#b60000}.qbr-connect-section__connected-accounts__-7-ps:hover svg path{fill:#b60000}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"qbr-connect-section": "qbr-connect-section__CWuHy",
	"qbr-connect-section__heading": "qbr-connect-section__heading__A5dyv",
	"qbr-connect-section__accounts-container": "qbr-connect-section__accounts-container__J0ckN",
	"qbr-connect-section__connected-accounts": "qbr-connect-section__connected-accounts__-7-ps"
};
export default ___CSS_LOADER_EXPORT___;
