import {Fragment, h} from 'preact';
import {useState, useEffect} from 'preact/hooks';
import styles from './LoginRibbon.module.scss';
import {LoginRibbonProps, AuthState} from '../../@types';
import {capitaliseName, handleLongName} from '../../utils';
import ChevronDown from '../../assets/chevron-down.svg';
import CloseIcon from '../../assets/close-icon.svg';
import ProfileIcon from '../../assets/profile-icon.svg';

const LOGIN_BUTTON = 'qdd-login-ribbon__button';
const loginRibbonButtonStyle = styles[LOGIN_BUTTON];
const buttonOpenStyle = styles[`${LOGIN_BUTTON}--open`];
const buttonClosedStyle = styles[`${LOGIN_BUTTON}--closed`];
const buttonLoggedOutStyle = styles[`${LOGIN_BUTTON}--logged-out`];
const buttonLoggedInStyle = styles[`${LOGIN_BUTTON}--logged-in`];
const buttonExistingStyle = styles[`${LOGIN_BUTTON}--existing`];

function softIdentityDataIsValid(softIdentityObject) {
  const parsedIdentityObject = JSON.parse(softIdentityObject);
  const lastUpdatedDate = new Date(parsedIdentityObject.lastUpdatedDate);
  const currentDate = new Date();
  const daysDifference = Math.floor(
    (currentDate.getTime() - lastUpdatedDate.getTime()) / (1000 * 60 * 60 * 24)
  );
  if (daysDifference > 180) {
    localStorage.removeItem('qff_soft_identity');
  }
  return daysDifference <= 180 && !!parsedIdentityObject.displayName;
}

export function LoginRibbon({
  handleMenuClick,
  menuOpened,
  isAuthenticated
}: Readonly<LoginRibbonProps>) {
  const [loginDialog, setLoginDialogContent] = useState(null);
  const softIdentityObject = localStorage.getItem('qff_soft_identity');
  let authState: AuthState = AuthState.LOGGED_OUT;
  let buttonAuthStyle = buttonLoggedOutStyle;

  const windowLoginDialog = window['loginDialog'];

  useEffect(() => {
    setLoginDialogContent(windowLoginDialog);
  }, [windowLoginDialog]);

  if (isAuthenticated) {
    authState = AuthState.LOGGED_IN;
    buttonAuthStyle = buttonLoggedInStyle;
  }
  if (
    !isAuthenticated &&
    softIdentityObject &&
    softIdentityDataIsValid(softIdentityObject)
  ) {
    authState = AuthState.EXISTING;
    buttonAuthStyle = buttonExistingStyle;
  }

  return (
    <button
      onClick={handleMenuClick}
      className={`${loginRibbonButtonStyle} ${
        menuOpened ? buttonOpenStyle : buttonClosedStyle
      } ${buttonAuthStyle}`}
      aria-expanded={menuOpened}
      data-testid="qdd-login-ribbon-portal"
    >
      {menuOpened ? (
        <OpenedState loginDialog={loginDialog} />
      ) : (
        <ClosedState loginDialog={loginDialog} authState={authState} />
      )}
    </button>
  );
}

function OpenedState({loginDialog}) {
  return (
    <Fragment>
      <img src={CloseIcon} alt="" />
      <p className="close">{loginDialog?.ui?.ribbonCloseLabel}</p>
    </Fragment>
  );
}

function ClosedState({authState, loginDialog}) {
  const qffAuth = window['qff_auth'];
  const title = capitaliseName(qffAuth?.getTitle());
  const firstName = capitaliseName(qffAuth?.getFirstName());
  const lastName = capitaliseName(qffAuth?.getLastName());
  const pointsBalance = qffAuth?.getPoints();
  const formattedPoints = Number(pointsBalance)?.toLocaleString('en-US');

  const EXISTING_USER = 'existing-user';
  const TEXT_CONTAINER = 'text-container';
  const existingUserStyle = styles[EXISTING_USER];
  const profileIconStyle = styles[`${EXISTING_USER}__profile-icon`];
  const textContainerStyle = styles[`${EXISTING_USER}__text-container`];
  const textContainerNameStyle =
    styles[`${EXISTING_USER}__${TEXT_CONTAINER}--name`];
  const textContainerCtaStyle =
    styles[`${EXISTING_USER}__${TEXT_CONTAINER}--cta`];
  const textContainerCtaShortStyle =
    styles[`${EXISTING_USER}__${TEXT_CONTAINER}--cta-short`];

  if (authState === AuthState.LOGGED_IN) {
    return (
      <div
        className={styles[`qdd-logged-in-user`]}
        data-testid="login-ribbon-logged-in-closed"
      >
        <span className={styles[`qdd-logged-in-user__content-wrapper`]}>
          <img src={ProfileIcon} className={profileIconStyle} alt="" />
          <span>
            <p className={styles[`qdd-logged-in-user__name`]}>
              {handleLongName(`${title} ${firstName} ${lastName}`)}
            </p>
            <p>{formattedPoints} points</p>
          </span>
        </span>
        <img
          className={styles[`icon__chevron-down`]}
          src={ChevronDown}
          alt=""
        />
      </div>
    );
  }
  if (authState === AuthState.EXISTING) {
    const softIdentityObject = localStorage.getItem('qff_soft_identity');
    const displayName = softIdentityObject
      ? JSON.parse(softIdentityObject).displayName
      : '';
    return (
      <div className={existingUserStyle}>
        <span>
          <img src={ProfileIcon} className={profileIconStyle} alt="" />
          <span className={textContainerStyle}>
            <span
              className={textContainerNameStyle}
              data-testid="login-ribbon-details"
            >
              {handleLongName(`Hello, ${displayName}`)}
            </span>
            <span className={textContainerCtaStyle}>
              Log in to your account
            </span>
            <span className={textContainerCtaShortStyle}>
              {loginDialog?.ui?.ribbonLoginLabel}
              <img src={ChevronDown} alt="" />
            </span>
          </span>
        </span>
        <img src={ChevronDown} alt="" />
      </div>
    );
  }
  return (
    <div
      className={`${styles['qdd-login-ribbon__log-in']}`}
      data-testid="login-ribbon-logged-out-closed"
    >
      Log in <img src={ChevronDown} alt="" />
    </div>
  );
}
