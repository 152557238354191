import {h} from "preact";
import styles from "./LogoutButton.module.scss";
import {pushAnalyticsDataEvent} from "../../utils/pushAnalyticsDataEvent";

export function LogoutButton() {
    const buttonLabel = "Log out";
    const handleClick = () => {
        pushAnalyticsDataEvent({
            event: {
                eventAction: "CTA Click",
                eventCategory: "Frequent Flyer Login",
                eventLabel: buttonLabel,
            },
            journey: {journeyStep: "Logged Out"},
        });
        localStorage.removeItem("qff_soft_identity");
        window["oauth"].logout();
    };

    return (
        <button className={`${styles["profile__logout"]}`} onClick={handleClick}>
            {buttonLabel}
        </button>
    );
}
